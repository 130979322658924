.navbar .container-fluid {
  background-color: #161515;
}
.custom-size {
  width: 80%;
  height: 80%;
  object-fit: cover; /* Ensures the content covers the entire area */
  border-radius: 15px;
}

.navbar-custom .nav-link {
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-custom .nav-link:hover {
  color: #f8d210; /* Elegant hover color */
  transform: scale(1.1); /* Slight zoom effect */
}
