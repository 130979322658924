.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.content li {
  list-style-type: none;
}
.content img {
  width: 300px;
  height: 300px;
}
/* Add this CSS to your ViewContent.css or a relevant stylesheet */
.scrollable {
  max-height: 80vh; /* Adjust the height as needed */
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.scrollable::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}
