/* General container for registration */
.registration-container {
  background-image: url("../Assets/homeo2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  color: white;
  font-weight: bolder;
}
.registration-container label {
  text-shadow: 3px 3px 5px rgb(15, 15, 14);
}
